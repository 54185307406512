<template>
    <v-card
        class="mx-auto mb-12"
        style="background-color: unset;"
        elevation="0"
    >
        <h2 class="text-center">Información Básica</h2>
        <v-form
            class="px-5"
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-select
                v-model="collectionType"
                :items="collectionTypes"
                :rules="collectionTypeRules"
                label="Tipo de Recaudo"
                :disabled="blockMode"
                required
            ></v-select>

            <v-autocomplete
                v-if="collectionType === 'Aportante'"
                label="Aportante"
                v-model="contributor"
                :items="contributors"
                item-text="name"
                item-value="_id"
                :disabled="blockMode"
            ></v-autocomplete>

            <v-autocomplete
                v-if="collectionType === 'Afiliado'"
                label="Afiliado"
                v-model="affiliate"
                :items="affiliates"
                item-text="fullName"
                item-value="_id"
                :disabled="blockMode"
            ></v-autocomplete>

            <v-select
                v-model="year"
                :items="years"
                :rules="yearRules"
                label="Año del Periodo de Pago"
                required
            ></v-select>

            <v-autocomplete
                label="Mes del Periodo de Pago"
                v-model="month"
                required
                :items="months"
                :rules="monthRules"
                item-text="name"
                item-value="value"
            ></v-autocomplete>
        </v-form>
    </v-card>
</template>

<script>
import Storage from "@/modules/storage";

const store = new Storage();

export default {
    data() {
        return {
            blockMode: false,
            mountedFinish: false,
            valid: false,
            collectionType: '',
            collectionTypes: ['Aportante', 'Afiliado'],
            collectionTypeRules: [
                v => !!v || 'El Tipo de Recaudo es requerido',
            ],
            contributor: '',
            contributors: [],
            affiliate: '',
            affiliates: [],
            year: '',
            years: [],
            yearRules: [
                v => !!v || 'El Año es requerido',
            ],
            month: '',
            months: [
                {name: 'Enero', value: 1},
                {name: 'Febrero', value: 2},
                {name: 'Marzo', value: 3},
                {name: 'Abril', value: 4},
                {name: 'Mayo', value: 5},
                {name: 'Junio', value: 6},
                {name: 'Julio', value: 7},
                {name: 'Agosto', value: 8},
                {name: 'Septiembre', value: 9},
                {name: 'Octubre', value: 10},
                {name: 'Noviembre', value: 11},
                {name: 'Diciembre', value: 12},
            ],
            monthRules: [
                v => !!v || 'El Mes es requerido',
            ],
        }
    },
    props: ['validate', 'default'],
    watch: {
        validate: function(value) {
            if (value) {
                this.$refs.form.validate();
            }
        },
        default: function(value) {
           if (value) {
               const checkMount = setInterval(() => {
                   if (this.mountedFinish) {
                        this.collectionType = value.collectionType;
                        this.contributor = value.contributor;
                        this.affiliate = value.affiliate;
                        this.year = value.year;
                        this.month = value.month;
                        this.blockMode = true;
                        clearInterval(checkMount);
                   }
               }, 100);
            } 
        }
    },
    updated() {
        this.$emit('valid', {
            step: 1,
            valid: this.valid,
            data: {
                collectionType: this.collectionType,
                contributor: this.contributor,
                affiliate: this.affiliate,
                year: this.year,
                month: this.month,
            }
        })
    },
    async mounted() {
        const contributorData = await store.getData("contributor");
        const branchData = await store.getData("branch");
        const affiliateData = await store.getData("affiliate");

        contributorData.sort(function(a, b) {
            return a.name.localeCompare(b.name);
        });
        contributorData.forEach(contributor => {
            if (contributor.office === this.$route.params._id) {
                this.contributors.push(contributor); 
            }
        });

        affiliateData.sort(function(a, b) {
            return a.name.localeCompare(b.name);
        });
        affiliateData.forEach(affiliate => {
            branchData.forEach(branch => {
                if (branch._id === affiliate.branch) {
                    affiliate.contributor = branch.contributor;
                }
            });
            contributorData.forEach(contributor => {
                if (contributor._id === affiliate.contributor) {
                    affiliate.office = contributor.office;
                }
            });
            if (affiliate.office === this.$route.params._id) {
                affiliate.fullName = affiliate.ID + ' - ' + affiliate.name;
                this.affiliates.push(affiliate); 
            }
        });

        this.years.push(new Date().getFullYear());
        this.years.push(new Date().getFullYear()-1);

        this.mountedFinish = true;
    }
}
</script>