<template>
    <v-card
        class="mx-auto mb-12"
        style="background-color: unset;"
        elevation="0"
    >
        <h2 class="text-center">Recaudo</h2>
        <v-simple-table class="mb-5">
            <template v-slot:default>
                <tbody>
                    <tr
                    v-for="item in desserts"
                    :key="item.name"
                    >
                    <td>{{ item.name }}</td>
                    <td>{{ item.amount }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-expansion-panels class="mb-5" v-if="collectionType == 'Aportante'">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    Resumen
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div v-for="item in dessertsSummary" :key="item._id">
                        <v-simple-table class="my-5" dense>
                            <template v-slot:default>
                                <tbody>
                                    <tr
                                    v-for="subItem in item.data"
                                    :key="subItem.name"
                                    >
                                    <td>{{ subItem.name }}</td>
                                    <td>{{ subItem.amount }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <v-divider style="border-bottom: 2px solid;"></v-divider>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-form
            class="px-5"
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-text-field
                v-model="amount"
                :rules="amountRules"
                type="number"
                label="Valor del Recaudo"
                prefix="$"
                required
            ></v-text-field>
        </v-form>
    </v-card>
</template>

<script>
import Storage from "@/modules/storage";
const store = new Storage();
import { prettyCurrency } from "@/modules/tools";

export default {
    data() {
        return {
            mountedFinish: false,
            valid: false,
            amount: 0,
            amountMin: 0,
            amountMax: 1,
            amountRules: [
                v => !!v || 'El Salario es requerido',
                v => (v && v >= this.amountMin) || `El Recaduo debe ser un número superior a ${this.amountMin}`,
                v => (v && v <= this.amountMax) || `El Recaduo debe ser un número inferior a ${this.amountMax}`,
            ],
            base: 0,
            EPS: 0,
            AFP: 0,
            ARL: 0,
            CCF: 0,
            supportFee: 0,
            suggestedAmount: 0,
            collectionType: '',
            desserts: [],
            dessertsSummary: []
        }
    },
    props: ['validate', 'default'],
    methods: {
        roundCurrency(amount, roundRange) {
            roundRange = 10 ** roundRange;
            amount = Math.round(amount);
            amount /= roundRange;
            amount ++;
            amount = Math.floor(amount);
            return amount * roundRange;
        },
        async getBasicData(affiliateId) {
            const data = {};
            const affiliate = await store.getData("affiliate", affiliateId);
            const country = await store.getData("country", localStorage.getItem('user.country'));
            const minimumSalary = country.minimumSalary;

            if (!affiliate) {
                return {
                    base: 0,
                    ARL: 0,
                    EPS: 0,
                    AFP: 0,
                    CCF: 0,
                    supportFee: 0
                }
            }

            if (affiliate.minimumSalary || affiliate.salary < minimumSalary) {
                data.base = minimumSalary;
            } else {
                data.base = affiliate.salary;
            }

            if (affiliate.ARLRate == 'I') {
                data.ARL = this.roundCurrency(data.base * 0.00522, 2);
            } else if (affiliate.ARLRate == 'II') {
                data.ARL = this.roundCurrency(data.base * 0.01004, 2);
            } else if (affiliate.ARLRate == 'III') {
                data.ARL = this.roundCurrency(data.base * 0.02436, 2);
            } else if (affiliate.ARLRate == 'IV') {
                data.ARL = this.roundCurrency(data.base * 0.0435, 2);
            } else if (affiliate.ARLRate == 'V') {
                data.ARL = this.roundCurrency(data.base * 0.0696, 2);
            } else {
                data.ARL = 0;
            }

            if (affiliate.EPS) {
                data.EPS = this.roundCurrency(data.base * 0.04, 2);
            } else {
                data.EPS = 0;
            }

            if (affiliate.AFP) {
                data.AFP = this.roundCurrency(data.base * 0.16, 2);
            } else {
                data.AFP = 0;
            }

            if (affiliate.CCF) {
                data.CCF = this.roundCurrency(data.base * 0.04, 2);
            } else {
                data.CCF = 0;
            }

            data.supportFee = affiliate.supportFee;

            return data;
        }
    },
    watch: {
        validate: function(value) {
            if (value) {
                this.$refs.form.validate();
            }
        },
        default: function(value) {
           if (value) {
               const checkMount = setInterval(async () => {
                   if (this.mountedFinish) {
                        let data;

                        this.collectionType = value.collectionType;
                        if (value.collectionType == 'Afiliado') {
                            data = await this.getBasicData(value.affiliate);
                            this.base = data.base;
                            this.EPS = data.EPS;
                            this.AFP = data.AFP;
                            this.ARL = data.ARL;
                            this.CCF = data.CCF;
                            this.supportFee = data.supportFee;
                        } else if (value.collectionType == 'Aportante') {
                            const branchData = await store.getData("branch");
                            const affiliateData = await store.getData("affiliate");

                            this.base = 0;
                            this.EPS = 0;
                            this.AFP = 0;
                            this.ARL = 0;
                            this.CCF = 0;
                            this.supportFee = 0;
                            this.dessertsSummary = [];

                            for (const affiliate in affiliateData) {
                                branchData.forEach(branch => {
                                    if (branch._id === affiliateData[affiliate].branch) {
                                        affiliateData[affiliate].contributor = branch.contributor;
                                    }
                                });
                                if (affiliateData[affiliate].contributor == value.contributor) {
                                    data = await this.getBasicData(affiliateData[affiliate]._id);

                                    this.base += data.base;
                                    this.EPS += data.EPS;
                                    this.AFP += data.AFP;
                                    this.ARL += data.ARL;
                                    this.CCF += data.CCF;
                                    this.supportFee += data.supportFee;

                                    this.dessertsSummary.push({
                                        data: [
                                            { name: 'Nombre', amount: affiliateData[affiliate].name },
                                            { name: 'Valor Base', amount: await prettyCurrency(data.base) },
                                            { name: 'Aportes de Salud', amount: await prettyCurrency(data.EPS) },
                                            { name: 'Aportes de Pensión', amount: await prettyCurrency(data.AFP) },
                                            { name: 'Aportes de Riesgos Laborales', amount: await prettyCurrency(data.ARL) },
                                            { name: 'Aportes de Caja de Compensación', amount: await prettyCurrency(data.CCF) },
                                            { name: 'Cuota de Sostenimiento', amount: await prettyCurrency(data.supportFee) },
                                            { name: 'Total Sugerido', amount: await prettyCurrency(data.EPS + data.AFP + data.ARL + data.CCF + data.supportFee) }
                                        ]
                                    });
                                }
                            }
                        }

                        this.suggestedAmount = this.EPS + this.AFP + this.ARL + this.CCF + this.supportFee;

                        this.desserts = [
                            { name: 'Valor Base', amount: await prettyCurrency(this.base) },
                            { name: 'Aportes de Salud', amount: await prettyCurrency(this.EPS) },
                            { name: 'Aportes de Pensión', amount: await prettyCurrency(this.AFP) },
                            { name: 'Aportes de Riesgos Laborales', amount: await prettyCurrency(this.ARL) },
                            { name: 'Aportes de Caja de Compensación', amount: await prettyCurrency(this.CCF) },
                            { name: 'Cuota de Sostenimiento', amount: await prettyCurrency(this.supportFee) },
                            { name: 'Total Sugerido', amount: await prettyCurrency(this.suggestedAmount) }
                        ];
                        this.amount = this.suggestedAmount;
                        if (value.amount) {
                            this.amount = value.amount;
                        }
                        clearInterval(checkMount);
                   }
               }, 100);
            } 
        }
    },
    updated() {
        this.$emit('valid', {
            step: 2,
            valid: this.valid,
            data: {
                amount: this.amount
            }
        })
    },
    async mounted() {
        const schemaData = await store.getData("schema");
        schemaData.forEach(schema => {
            if (schema.store === "collection") {
                this.amountMin = schema.data.amount.min;
                this.amountMax = schema.data.amount.max;
            }
        });
        this.mountedFinish = true;
    },
}
</script>